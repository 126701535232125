import { useState } from "react";
import apiUrl from "./Api";

export default function ChangePassword({ setIsMenuOpened }) {
	const [oldPass, setOldPass] = useState("");
	const [newPass, setNewPass] = useState("");
	const [repeatNew, setRepeatNew] = useState("");

	const [promoMinus, setPromoMinus] = useState(0.05);

	function change() {
		if (!oldPass) return window.addPush("Введите старый пароль");
		if (!newPass) return window.addPush("Введите новый пароль");
		if (!repeatNew) return window.addPush("Повторите новый пароль");

		if (newPass !== repeatNew) return window.addPush("Пароли не совпадают");

		fetch(`${apiUrl}/users/changePassword`, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				previousPassword: oldPass,
				newPassword: newPass,
			}),
		})
			.then((response) => response.json())
			.then((r) => {
				if (r.message) {
					window.addPush(r.message);
				} else {
					window.addPush("Неизвестная ошибка");
				}
			})
			.catch((e) => {
				console.log(e);
			});
	}


	return (
		<div className="MicroMenu">
			<input
				value={oldPass}
				onChange={(e) => {
					setOldPass(e.target.value);
				}}
				type="password"
				placeholder="Старый пароль"
			/>
			<input
				value={newPass}
				onChange={(e) => {
					setNewPass(e.target.value);
				}}
				type="password"
				placeholder="Новый пароль"
			/>
			<input
				value={repeatNew}
				onChange={(e) => {
					setRepeatNew(e.target.value);
				}}
				type="password"
				placeholder="Повторите новый пароль"
			/>
			<button
				onClick={() => {
					change();
				}}
			>
				Изменить
			</button>
			<button
				onClick={() => {
					setIsMenuOpened(false);
				}}
			>
				Закрыть
			</button>
		</div>
	);
}
