import React, { act, useMemo } from "react";
import Attachments from "./Attachments";

function formatDate(date) {
  return `${String(date.getHours()).padStart(2, "0")}:${String(
    date.getMinutes()
  ).padStart(2, "0")}`;
}

function formatNewDay(timestamp) {
  const date = new Date(timestamp);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  if (date.toDateString() === today.toDateString()) {
    return "Сегодня";
  } else if (date.toDateString() === yesterday.toDateString()) {
    return "Вчера";
  } else {
    return date.toDateString();
  }
}

const MessageItem = ({ message, nextMessage, active, setOverlayId }) => {
  const isNewDay = useMemo(() => {
    if (!nextMessage) return true;
    const currentDate = new Date(message.timestamp).toDateString();
    const nextDate = new Date(nextMessage.timestamp).toDateString();
    return currentDate !== nextDate;
  }, [message.timestamp, nextMessage?.timestamp]);

  return (
    <>
      <div
        key={message.id}
        className={`Msg ${message.support ? "Our" : ""} ${
          active === message.id ? "Active" : ""
        }`}
      >
        {message.support ? (
          <p className="Username">{message.support.username}</p>
        ) : null}
        <HighlightRegex setOverlayId={setOverlayId} message={message.message} />
        <Attachments
          key={`attach-${message.id}`}
          attach={message.attachments}
        />
        <p className="Date">{formatDate(new Date(message.timestamp))}</p>
      </div>
      {isNewDay && (
        <div className="NewDay">{formatNewDay(message.timestamp)}</div>
      )}
    </>
  );
};
const HighlightRegex = ({ message, setOverlayId }) => {
  const regexList = [/([a-zA-Z0-9]{24})/, /([0-9]{4,9})/, /([a-zA-Z0-9]{40})/];

  const splitByRegexes = (text, regexList) => {
    const parts = [];
    let remainingText = text;

    while (remainingText) {
      let earliestMatch = null;
      let earliestRegex = null;

      for (const regex of regexList) {
        const match = regex.exec(remainingText);
        if (match && (!earliestMatch || match.index < earliestMatch.index)) {
          earliestMatch = match;
          earliestRegex = regex;
        }
      }

      if (earliestMatch) {
        if (earliestMatch.index > 0) {
          parts.push(remainingText.slice(0, earliestMatch.index));
        }

        parts.push({ text: earliestMatch[0], regex: earliestRegex });

        remainingText = remainingText.slice(
          earliestMatch.index + earliestMatch[0].length
        );
      } else {
        parts.push(remainingText);
        break;
      }
    }

    return parts;
  };

  const parts = splitByRegexes(message, regexList);

  return (
    <p>
      {parts.map((part, index) =>
        typeof part === "object" && part.regex ? (
          <span
            key={index}
            onClick={() => {
              setOverlayId(part.text);
            }}
            className="Clickable"
          >
            {part.text}
          </span>
        ) : (
          <span key={index}>{part}</span>
        )
      )}
    </p>
  );
};

export default MessageItem;
