
export default function NoDialog() {
   
	return (
		<div className="NoDialog">
			<div className="Logo">
				<img src="/logo.webp" alt="Logo" />
				<p>Support</p>
			</div>
			<p>Добро пожаловать</p>
		</div>
	);
}
