import { RiLoader2Fill } from "react-icons/ri";

function Loader({ style }) {
	return (
		<div style={style} className="Loader">
			<RiLoader2Fill size={32} />
		</div>
	);
}

export default Loader;
