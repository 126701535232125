import "../css/TemplateWindow.css";

const template = [
	"Здравствуйте",
	"Передал ваше обращение нашим специалистам, в ближайшее время поступит ответ, я вам его сразу же передам.",
	"Средства в процессе отправления.",
	"Средства приходят в течении 30 минут, после вашей оплаты, пожалуйста ожидайте.",
	"Отправил запрос команде на ускорение, приносим извинения за возникшую задержку, вероятно возникла проблема с банком.",
	`Все актуальные направления обменов можно посмотреть на сайте или в боте.

https://yellowchanger.com
https://t.me/yellowchanger_bot
`,
	`Транзакция заблокирована AML-системой по причине высокого риска. 

Для возврата средств, пожалуйста, предоставьте запрашиваемые данные:
1. Источник средств
- За какие услуги была получена сумма
- Скриншоты, подтверждающие получение и вывод суммы
- Дополнительная информация, которую вы считаете нужным предоставить

Пожалуйста, не отправляйте нам скриншот из Explorer, данная информация общедоступная и не котируется как подтверждающие данные.
`,
	`Ручные обмены не проводим, вы можете сделать автоматический обмен через наш сайт или бота. Задержек не наблюдается

https://yellowchanger.com
https://t.me/yellowchanger_bot
`,
	`Грязные деньги не обмениваем, но если у ваших средств общий амл до 80% и красные метки до 10% каждая то мы не считаем их грязными и вы можете их обменять через наш сайт или бота.

https://yellowchanger.com
https://t.me/yellowchanger_bot`
];

export default function TemplateWindow({
	isTemplateWindowOpened,
	setIsTemplateWindowOpened,
	setText,
}) {
	function processTemplate(key) {
		setIsTemplateWindowOpened(false);
		setText(template[key]);
	}

	if (!isTemplateWindowOpened) return null;
	return (
		<div className="Template">
			<div className="TemplatesList">
				{template.map((x, key) => (
					<div
						onClick={() => {
							processTemplate(key);
						}}
						className="TemplateItem"
					>
						<p>{x}</p>
					</div>
				))}
			</div>
			<div className="Ellipse1"></div>
		</div>
	);
}
