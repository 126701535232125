import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import '../css/DatePicker.css';

const DatePicker = ({ selectedDate, setSelectedDate }) => {

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const getDaysInMonth = (year, month) => {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  const getFirstDayOfMonth = (date) =>
    new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  const getLastDayOfMonth = (date) =>
    new Date(date.getFullYear(), date.getMonth() + 1, 0).getDay();
  const getPreviousMonth = (date) =>
    new Date(date.getFullYear(), date.getMonth() - 1, 1);
  const getNextMonth = (date) =>
    new Date(date.getFullYear(), date.getMonth() + 1, 1);

  const handleDateClick = (day) => {
    setSelectedDate(day);
    setIsCalendarOpen(false);
  };

  const toggleCalendar = () => setIsCalendarOpen(!isCalendarOpen);

  const isSelected = (day) => {
    return (
      day.getDate() === selectedDate.getDate() &&
      day.getMonth() === selectedDate.getMonth() &&
      day.getFullYear() === selectedDate.getFullYear()
    );
  };

  const renderCalendar = () => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();

    const daysInMonth = getDaysInMonth(year, month);
    const firstDayOfMonth = getFirstDayOfMonth(currentMonth);
    const lastDayOfMonth = getLastDayOfMonth(currentMonth);

    const leadingDays = Array.from({ length: firstDayOfMonth }, () => null);
    const trailingDays = Array.from({ length: 6 - lastDayOfMonth }, () => null);

    const calendarDays = [...leadingDays, ...daysInMonth, ...trailingDays];

    return (
      <div className="DatePickerCalendar">
        <div className="Ellipse1"/>
        <div className="DatePickerHeader">
          <FaChevronLeft
            onClick={() => setCurrentMonth(getPreviousMonth(currentMonth))}
          />
          <span>{`${year} - ${month + 1}`}</span>
          <FaChevronRight
            onClick={() => setCurrentMonth(getNextMonth(currentMonth))}
          />
        </div>
        <div className="DatePickerGrid">
          {calendarDays.map((day, index) => (
            <div
              key={index}
              className={`DatePickerDay ${day && isSelected(day) ? "Selected" : ""}`}
              style={{
                ...(day ? { cursor: "pointer", color: "#fff" } : { visibility: "hidden" }),
              }}
              onClick={() => day && handleDateClick(day)}
            >
              {day ? day.getDate() : ""}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="DatePicker">
      <input
        type="text"
        value={selectedDate ? selectedDate.toDateString() : ""}
        readOnly
        onClick={toggleCalendar}
        className="DatePickerInput"
      />
      {isCalendarOpen && renderCalendar()}
    </div>
  );
};

export default DatePicker;
