import { Route, Routes } from "react-router-dom";
import Messenger from "./pages/Messenger";
import "./App.css";
import { NotificationProvider } from "./components/NotificationProvider";
import Login from "./pages/Login";
import Info from "./pages/Info";
import Admin from "./pages/Admin";
import Push from "./components/Push";
export default function App() {
	return (
		<div className="App">
			<NotificationProvider>
				<Routes>
					<Route path="/messenger/:chatId" element={<Messenger />} />
					<Route path="/messenger/:chatId/:messageId" element={<Messenger />} />
					<Route path="/messenger" element={<Messenger />} />
					<Route path="/login" element={<Login />} />
					<Route path="/info" element={<Info />} />
					<Route path="/admin" element={<Admin />} />
					<Route path="/" element={<Login />} />
					<Route
						path="*"
						element={
							<div className="e404">
								<img src="/404.jpg" />
							</div>
						}
					/>
				</Routes>
			</NotificationProvider>
			<div className="Background">
				<div className="Ellipse1" />
				<div className="Ellipse2" />
			</div>
			<Push />
		</div>
	);
}
