import React, { createContext, useContext, useState } from "react";
import { FaExclamation } from "react-icons/fa";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
	const [notifications, setNotifications] = useState([]);

	const addNotification = (message) => {
		const id = Date.now();
		setNotifications((prev) => [...prev, { id, message }]);

		setTimeout(() => {
			removeNotification(id);
		}, 5000);
	};

	const removeNotification = (id) => {
		setNotifications((prev) => prev.filter((notif) => notif.id !== id));
	};

	window.addPush = addNotification;

	return (
		<NotificationContext.Provider value={{ notifications, removeNotification }}>
			{children}
			<NotificationList />
		</NotificationContext.Provider>
	);
};

export const useNotification = () => useContext(NotificationContext);

const NotificationList = () => {
	const { notifications, removeNotification } = useNotification();

	return (
		<div className="NotificationContainer">
			{notifications.map(({ id, message }) => (
				<div
					key={id}
					className="Notification"
					onClick={() => removeNotification(id)}
				>
					<FaExclamation /> <p>{message}</p>
				</div>
			))}
		</div>
	);
};
