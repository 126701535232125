import { GrClose } from "react-icons/gr";
import "../css/Overlay.css";
import { useEffect, useRef, useState } from "react";
import Loader from "./Loader";
import apiUrl from "./Api";

const networkInfo = {
  DASH: {
    DASH: "2 confirmations",
  },
  DAI: {
    POLYGON: "1 block",
  },
  BTC: {
    BTC: "1 confirmation",
  },
  BCH: {
    BCH: "2 blocks",
  },
  TON: {
    TON: "1 block",
  },
  MATIC: {
    POLYGON: "1 block",
  },
  LTC: {
    LTC: "2 confirmations",
  },
  ETH: {
    ARBITRUM: "1 block",
    ERC20: "1 block",
  },
  USDT: {
    ERC20: "1 block",
    TRC20: "19 blocks",
    AVALANCHE: "1 block",
    BEP20: "5 blocks",
    POLYGON: "1 block",
  },
  XMR: {
    XMR: "10 blocks",
  },
  USDC: {
    AVALANCHE: "1 block",
  },
  VERSE: {
    ERC20: "1 block",
  },
};

export default function Overlay({
  setOverlayId,
  overlayId,
  isOverlayOpened,
  setIsOverlayOpened,
}) {
  const [overlayText, setOverlayText] = useState("");
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const [tradeInfo, setTradeInfo] = useState([]);
  const [infoLoading, setInfoLoading] = useState(false);

  const [requisites, setRequisites] = useState("");
  const [bank, setBank] = useState("");

  const [currTrade, setCurrTrade] = useState(false);

  const overlay = useRef();

  useEffect(() => {
    setOverlayText(overlayId);
    setIsOverlayOpened(overlayId !== "");
  }, [overlayId]);

  useEffect(() => {
    const centerX = window.innerWidth / 2;
    const centerY = window.innerHeight / 2;
    setPosition({ x: centerX, y: centerY });
  }, []);

  useEffect(() => {
    const halfWidth = overlay.current?.clientWidth / 2;
    const halfHeight = overlay.current?.clientHeight / 2;

    const maxX = window.innerWidth - halfWidth;
    const maxY = window.innerHeight - halfHeight;

    if (maxX && maxY) {
      setPosition({
        x: Math.min(position.x, maxX),
        y: Math.min(position.y, maxY),
      });
    }
  }, [isOverlayOpened]);

  function getNetworkInfo(inputString) {
    const sanitizedString = inputString
      .toUpperCase()
      .replace(/[()>/]/g, " ")
      .replace(/\s+/g, " ")
      .trim();

    const words = sanitizedString.split(" ");
    const results = new Set();
    let currentCrypto = null;

    for (let word of words) {
      if (networkInfo[word]) {
        currentCrypto = word;
      }

      if (currentCrypto && networkInfo[currentCrypto][word]) {
        const result = `${currentCrypto} (${word}): ${networkInfo[currentCrypto][word]}`;
        results.add(result);
      }
    }

    return results.size > 0 ? Array.from(results).join("; ") : null;
  }
  const startDragging = (e) => {
    if (
      e.target.className === "ScrollContent" ||
      e.target.className === "Overlay"
    ) {
      e.preventDefault();
      setDragging(true);
      setOffset({
        x: e.clientX - position.x,
        y: e.clientY - position.y,
      });
    }
  };

  const stopDragging = () => {
    setDragging(false);
  };

  const handleDragging = (event) => {
    if (!dragging) return;

    const overlayElement = document.querySelector(".Overlay");

    const { clientWidth, clientHeight } = overlayElement;
    const halfWidth = clientWidth / 2;
    const halfHeight = clientHeight / 2;

    const maxX = window.innerWidth - halfWidth;
    const maxY = window.innerHeight - halfHeight;

    const posX = event.clientX - offset.x;
    const posY = event.clientY - offset.y;

    setPosition({
      x: Math.max(halfWidth, Math.min(maxX, posX)),
      y: Math.max(halfHeight, Math.min(maxY, posY)),
    });
  };

  useEffect(() => {
    if (dragging) {
      document.addEventListener("mousemove", handleDragging);
      document.addEventListener("mouseup", stopDragging);
    } else {
      document.removeEventListener("mousemove", handleDragging);
      document.removeEventListener("mouseup", stopDragging);
    }
    return () => {
      document.removeEventListener("mousemove", handleDragging);
      document.removeEventListener("mouseup", stopDragging);
    };
  }, [dragging]);

  function forceSetInvalidRequisites(id) {
    if (id === "") return;
    fetch(`${apiUrl}/proxy/setInvalidRequisites`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        exchangeId: id,
      }),
    })
      .then(async (r) => {
        var rJson = await r.json();
        window.addPush(rJson.message);
        getTradeInfo();
      })
      .catch((error) =>
        console.error("Error while forceSetInvalidRequisites:", error)
      );
  }

  function forceCancelTrade(id) {
    if (id === "") return;
    fetch(`${apiUrl}/proxy/cancelTrade`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        exchangeId: id,
      }),
    })
      .then(async (r) => {
        var rJson = await r.json();
        window.addPush(rJson.message);
        getTradeInfo();
      })
      .catch((error) => console.error("Error while forceCancelTrade:", error));
  }

  function updateRequisites(id) {
    if (id === "") return;
    fetch(`${apiUrl}/proxy/changeRequisites`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        exchangeId: id,
        new_data: requisites,
        newbank: bank,
      }),
    })
      .then(async (r) => {
        var rJson = await r.json();
        window.addPush(rJson.message);
        getTradeInfo();
      })
      .catch((error) => console.error("Error while updateRequisites:", error));
  }

  function speedUpPayment(id) {
    if (id === "") return;
    fetch(`${apiUrl}/proxy/speedUpPayment`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        exchangeId: id,
      }),
    })
      .then(async (r) => {
        var rJson = await r.json();
        window.addPush(rJson.message);
        getTradeInfo();
      })
      .catch((error) => console.error("Error while speedUpPayment:", error));
  }

  function getTradeInfo() {
    if (overlayText === "") return false;
    setInfoLoading(true);
    fetch(`${apiUrl}/proxy/getTradeInfo`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        exchangeId: overlayText,
      }),
    })
      .then(async (r) => {
        var rJson = await r.json();
        console.log(r.status);
        if (r.status == 201) {
          setTradeInfo(rJson);
        } else {
          window.addPush(rJson.message);
        }
      })
      .catch((error) => console.error("Error while getTradeInfo:", error))
      .finally(() => {
        setInfoLoading(false);
      });
  }

  useEffect(() => {
    setTradeInfo([]);
    var t = setTimeout(() => {
      getTradeInfo();
      setCurrTrade(false);
    }, 500);

    return () => {
      clearTimeout(t);
    };
  }, [overlayText]);

  if (!isOverlayOpened) return false;

  return (
    <>
      <div
        className="Overlay"
        ref={overlay}
        style={{
          position: "absolute",
          top: `${position.y}px`,
          left: `${position.x}px`,
          cursor: dragging ? "grabbing" : "grab",
        }}
        onMouseDown={startDragging}
      >
        <div className="Head">
          <input
            value={overlayText}
            autoFocus={true}
            onChange={(e) => setOverlayText(e.target.value)}
            placeholder="ID обмена / хэш"
          />
          <GrClose
            onClick={() => {
              setIsOverlayOpened(false);
              setOverlayId("");
            }}
            size={18}
            className="Close"
          />
        </div>
        {infoLoading && <Loader style={{ height: "calc(100% - 20px)" }} />}
        <div className="ScrollContent">
          {currTrade || tradeInfo.length === 0
            ? null
            : tradeInfo.map((trade) => (
                <>
                  <p onClick={() => setCurrTrade(trade)}>
                    {trade.creationDate} /{" "}
                    {trade.direction
                      .replace(/SBPRUB/i, "СБП")
                      .replace(/SBPRUB_INSTANT/i, "СБП (моментальный)")
                      .replace(/SBERRUB/i, "СберБанк")}
                  </p>
                </>
              ))}

          {currTrade ? (
            <>
              {currTrade.status && (
                <p>
                  Статус: {currTrade.normalizedStatus} ({currTrade.status})
                </p>
              )}
              {currTrade.otcTradeStatus && (
                <p>Статус вывода рублей: {currTrade.otcTradeStatus}</p>
              )}
              {currTrade.direction && (
                <p>
                  Направление:{" "}
                  {currTrade.direction
                    .replace(/SBPRUB/i, "СБП")
                    .replace(/SBPRUB_INSTANT/i, "СБП (моментальный)")
                    .replace(/SBERRUB/i, "СберБанк")}
                </p>
              )}
              {currTrade.direction && (
                <p style={{ fontSize: "12px" }}>
                  {getNetworkInfo(currTrade.direction)}
                </p>
              )}
              {currTrade.otcDirection && (
                <p>Направление вывода рублей: {currTrade.otcDirection}</p>
              )}
              {currTrade.sendAmount && (
                <p>Сумма отправления: {currTrade.sendAmount}</p>
              )}
              {currTrade.getAmount && (
                <p>Сумма получения: {currTrade.getAmount}</p>
              )}
              {currTrade.exchType && <p>Тип обмена: {currTrade.exchType}</p>}
              {currTrade.creationDate && (
                <p>Дата создания: {currTrade.creationDate}</p>
              )}
              {currTrade.receivedDate && (
                <p>Дата отправки: {currTrade.receivedDate}</p>
              )}
              {currTrade.sendCredentials && (
                <p>Адрес для оплаты: {currTrade.sendCredentials}</p>
              )}
              {currTrade.getCredentials && (
                <p>Адрес получения: {currTrade.getCredentials}</p>
              )}
              {currTrade.userPaidHash && currTrade.userPaidHash !== "None" && (
                <p>
                  Хэш оплаты:{" "}
                  <a target="_blank" href={currTrade.userPaidHash}>
                    {currTrade.userPaidHash}
                  </a>
                </p>
              )}
              {currTrade.ourHash && currTrade.ourHash !== "None" && (
                <p>
                  Хэш отправки:{" "}
                  <a target="_blank" href={currTrade.ourHash}>
                    {currTrade.ourHash}
                  </a>
                </p>
              )}
              <div className="Buttons">
                {["Ожидание", "Обработка"].includes(currTrade.otcTradeStatus) &&
                  currTrade.status !== "3" &&
                  currTrade.status !== "successfull" && (
                    <button
                      className="Button"
                      onClick={() => {
                        forceSetInvalidRequisites(currTrade.exchangeId);
                      }}
                    >
                      Невалидные реквизиты
                    </button>
                  )}
                {currTrade.status &&
                  !["3", "successfull", "4", "cancel"].includes(
                    currTrade.status.toString()
                  ) && (
                    <button
                      className="Button"
                      onClick={() => {
                        forceCancelTrade(currTrade.exchangeId);
                      }}
                    >
                      Отменить обмен
                    </button>
                  )}

                {["Ожидание", "Обработка"].includes(currTrade.otcTradeStatus) &&
                currTrade.allowSpeedUp === true ? (
                  <button
                    className="Button"
                    onClick={() => {
                      speedUpPayment(currTrade.exchangeId);
                    }}
                  >
                    Ускорить выплату
                  </button>
                ) : null}
                {currTrade.otcTradeStatus === "Невалидные реквизиты" ? (
                  <>
                    <select
                      className="Select"
                      value={bank}
                      onChange={(e) => setBank(e.target.value)}
                    >
                      <option disabled value="">
                        Выберите новый банк
                      </option>
                      <option value="sbppsb">СБП ПСБ (Промсвязь)</option>
                      <option value="sbpakbars">СБП Ак-Барс</option>
                      <option value="sbprnkb">СБП РНКБ</option>
                      <option value="sbpotp">СБП ОТП</option>
                      <option value="sbpozon">СБП ОЗОН</option>
                      <option value="sbpmtc">СБП МТС</option>
                      <option value="sbppochtabank">СБП ПочтаБанк</option>
                      <option value="sbpumoney">СБП Юмани</option>
                      <option value="sbptinkoff">СБП Тинькофф</option>
                      <option value="sbpsber">СБП Сбер</option>
                      <option value="sbpraif">СБП Райфайзен</option>
                      <option value="sbpalfa">СБП Альфабанк</option>
                      <option value="sbpotkritie">СБП Открытие</option>
                      <option value="sbpvtb">СБП ВТБ</option>
                      <option value="sbpsovkombank">СБП Совкомбанк</option>
                      <option value="sbpgazprom">СБП Газпром</option>
                      <option value="sbprosbank">СБП Росбанк</option>
                      <option value="sberbank">СБЕР (Карта)</option>
                    </select>
                    <input
                      className="Input"
                      value={requisites}
                      onChange={(e) => setRequisites(e.target.value)}
                      placeholder="Новые реквизиты"
                    />
                    <button
                      className="Button"
                      onClick={() => {
                        updateRequisites(currTrade.exchangeId);
                      }}
                    >
                      Сохранить
                    </button>
                  </>
                ) : null}
                <button
                  className="Button"
                  onClick={() => {
                    setCurrTrade(false);
                  }}
                >
                  Назад
                </button>
              </div>
              <br />
              <br />
            </>
          ) : null}
        </div>
        <div className="Ellipse1"></div>
      </div>
    </>
  );
}
